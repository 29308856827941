'use client';

export default function GlobalError() {
  return (
    <html>
      <body>
        <div>Internal Server Error: Page failed to compose</div>
      </body>
    </html>
  );
}
